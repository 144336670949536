body {
  overflow-y: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url("../public/background.png");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-size: cover;
  background-position: left 50% top 100%;
  color: white;
}

.App-header-simple {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.search-select {
  text-align: left;
}

.app-title {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 1000;
  top: 25vh;  
}

.title-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vh;
  font-weight: 1000;
  top: 5vh;
}

.title-container-simple {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vh;
  font-weight: 1000;
  top: 5vh;
  color: black;
}

.subtitle {
  position: absolute;  
  font-size: 5vh;
  font-weight: 1000;
  top: 30vh;  
  font-size: 2.15rem;
  width: 100vw;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-bar-container {
  position: absolute;
  top: 40vh;
  padding: 10px;
}

.search-bar-container div .ant-select .ant-select-selector {
  background: black;
  height: 60px;
  width: min(60vw, 450px);
}

.search-bar-container div .ant-select .ant-select-selector {
  border: 1px;
}

.search-bar-container div .ant-select .ant-select-selector .ant-select-selection-placeholder {
  color: gray;
  font-size: 1rem;
  vertical-align: middle;
}

.search-bar-container div .ant-select .ant-select-selector .ant-select-selection-search input{
  height: 60px;
  color: white;
  font-size: 1rem;
  vertical-align: middle;
  line-height: 60px;  
}

.ant-select-dropdown {
  background: black;
}

.prefix-icon {
  vertical-align: -3px;
}

.search-bar-container div .search-button {
  width: calc(2 * min(60vw, 450px) + 20px);
  height: min(60px, 10vh);
  border: 0px;
  border-radius: 0;
  background-color: red;
  color: white;
  font-weight: 400;
  font-size: min(3vw, 18px);
}

.back-button-text {
  text-align: left;
  margin-top: -15px;
  font-size: 1.25rem;
  margin-left: 40px;  
  color: lightblue;
}

.back-button-text p {
  line-height: 0.25rem;
}

.back-button {
  text-align: left;
  cursor: pointer;
}

.back-div {
  text-align: right;
  padding: 10px;
  top: 50px;
  height: 120px;
  border-radius: 5% 0 0 5%;
  background: linear-gradient(90deg, #0086C2 85.22%, rgba(0, 134, 194, 0) 134.78%);
  min-width: 150px;
}

.details-container {
  width: min(100vw, 1200px);  
  flex-flow: unset;
}

.details-bio {  
  position: relative;
  width: 50%;
  text-align: center;
  left: 25%;
}

.details-bio .name {
  font-size: 18px;
  text-align: center;
}

.name-simple {
  font-size: 32px;
  text-align: left;
  margin-left: 10px;
}

.details-bio .bio {
  margin-top: 5px;  
}

.details-bio .bio .key {
  color: rgba(180, 191, 219, 0.5);
  font-size: 15px;
  text-align: left;
}

.details-bio .bio .value {
  color: rgba(180, 191, 219);
  font-size: 15px;
  text-align: right;
}

.score-container .score {
  color: lightblue;
  font-size: 32px;
  line-height: 20px;
  vertical-align: middle;
  font-weight: 700;
}

.score-container-simple .score {
  color: white;
  font-size: calc(2 * min(3vw, 24px));
  line-height: calc(2 * min(3vw, 24px));
  vertical-align: middle;
}

.score-container .score-title {
  color: rgba(0, 134, 194, 1);
  font-size: 15px;
  line-height: 20px;
}

.score-container-simple .score-title {
  color: white;
  font-size: calc(min(3vw, 28px));
  line-height: calc(min(3vw, 24px));
}

.score-rating {
  color: rgba(0, 134, 194, 1);
  font-size: 32px;
  line-height: calc(min(3vw, 24px));
}

.score-base {
  color: rgba(180, 191, 219, 0.33);
  font-size: 12px;
  line-height: 15px;
  vertical-align: middle;  
}

.score-container {
  text-align: left;
  margin-left: 10px;
}

.score-container-simple {
  text-align: left;
  margin-left: 10px;  
}

.score-divider-wrapper {
  height: 100%;
  line-height: 100%;  
  vertical-align: middle;
}

.score-divider {
  height: 60%;
  top: 20%;
  background-color:rgba(180, 191, 219, 1);
  margin-left: 10px;
  margin-right: 30px;
}

.MuiRating-iconFilled {
  color: red;
}

.score-rating .MuiRating-root label .MuiRating-iconEmpty {
  color: rgba(180, 191, 219, 1);
}

.summary-simple {  
  text-align: left;
  color: white;
}

.summary {  
  text-align: left;
  color: rgba(180, 191, 219, 1);
}

.summary-title {
  font-size: 16px;
}

.highlights  {  
  text-align: left;
  font-size: calc(min(3vw, 32px));
  line-height: min(3vw, 24px);
  margin-top: 20px;
  color: rgba(180, 191, 219, 1);  
}

.highlights .highlights-title {
  color: white;
  font-size: 16px;
  line-height: 21px;
}

.highlight-content {
  margin-left: 8px;
  font-size: 15px;
  line-height: 24px;
  margin-top: 10px;
}

.summary-content {
  line-height: 22px;
  font-size: 14px;
}

.details-container .detail-body {
  height: 100vh;
  background-color: rgba(8, 8, 17, 0.95);
  overflow-y: auto;
}

.details-container .detail-body::-webkit-scrollbar{    
  width: 10px;
}

.details-container .detail-body::-webkit-scrollbar-thumb {  
  background: rgba(0, 134, 194, 0.11);
}

.footer {
  position: absolute;
  bottom: 50px;
  font-size: 1rem;
}

.footer .disclaimer-link {
  text-decoration: underline;
  cursor: pointer;
}

.disclaimer-title {
  text-align: left;
  margin-top: 50px;
  color: rgba(180, 191, 219, 1);
}

.disclaimer-subtitle {
  text-align: left;
  margin-top: 20px;
  color: rgba(180, 191, 219, 1);
}

.disclaimer-content {
  text-align: left;
  font-size: calc(min(3vw, 18px));
  color: rgba(180, 191, 219, 1);
}

.disclaimer-list li {
  margin-top: 20px;  
}

.select-option {
  color: #B4BFDB;
}

.select-option-selected {
  color: #B4BFDB;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(0, 52, 153, 0.15);
}

.ant-rate .ant-rate-star-full div .ant-rate-star-first {
  color: rgba(222, 14, 40, 1)
}

.ant-rate .ant-rate-star-full div .ant-rate-star-second {
  color: rgba(222, 14, 40, 1)
}

.ant-rate .ant-rate-star-half div .ant-rate-star-first {
  color: rgba(222, 14, 40, 1)
}

.ant-rate .ant-rate-star-half div .ant-rate-star-second {
  color: rgba(0, 134, 194, 0.33);
}

.ant-rate-star-zero div .ant-rate-star-first  {
  color: rgba(0, 134, 194, 0.33);
}

.ant-rate-star-zero div .ant-rate-star-second  {
  color: rgba(0, 134, 194, 0.33);
}

.details-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  left: 0px;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: rgba(0, 134, 194, 1) !important;
}

.link-button {
  background-color: black;
  color: rgba(0, 134, 194, 1);
  font-size: 14px;
  border: 1px solid rgba(0, 134, 194, 1)
}

.link-button img {
  margin-right: 5px;
}