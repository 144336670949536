body {
    overflow-y: auto;
  }
  
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .search-select {
    text-align: left;
  }
  
  .title {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 5vh;
    font-weight: 1000;
    top: 5vh;
  }

  .app-title-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 900;
    line-height: 44px;
    top: 20vh;  
  }

  .subtitle-mobile {
    position: absolute;  
    font-size: 5vh;
    font-weight: 1000;
    top: 25vh;  
    font-size: 25px;
    line-height: 44px;
    width: 100vw;
  }
  
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  
  .search-bar-container-mobile div .ant-select .ant-select-selector {
    border: 1px;
  }
  
  .ant-select-dropdown {
    background: black;
  }
  
  .prefix-icon {
    vertical-align: -3px;
  }
  
  .back-mobile-button {
    text-align: left;
    margin-left: 16px;
    cursor: pointer;
    font-size: 17px;
    line-height: 22px;
    vertical-align: middle;
  }

  .back-mobile-button div {
    line-height: 22px;
    vertical-align: middle;
  }

  .scores .name-simple {
    font-size: 1rem;
    width: 90vw;
    padding-bottom: 1rem;
    font-weight: 500;
    margin-left: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.33);
  }
   
  .details-mobile-container {
    background-color: rgba(8, 8, 17, 0.95);
    position: fixed;
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    overflow: auto;
  }
  
  .details-mobile-bio {  
    position: relative;
    width: 70vw;
    text-align: left;
    left: calc(30vw / 2);
  }
  
  .details-mobile-bio .name {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    line-height: 24px;
  }
  
  .details-mobile-bio .bio {
    margin-top: 5px;  
  }
  
  .details-mobile-bio .bio .key {
    color: rgba(180, 191, 219, 0.5);
    font-size: 14px;
  }
  
  .details-mobile-bio .bio .value {
    color: rgba(180, 191, 219);
    font-size: 14px;
    text-align: right;
  }
  
  .score {
    color: lightblue;
    font-size: calc(2 * min(3vw, 24px));
    line-height: calc(2 * min(3vw, 24px));
    vertical-align: middle;
  }
  
  .score-rating {
    color: rgba(0, 134, 194, 1);
    font-size: calc(min(3vw, 24px));
    line-height: calc(min(3vw, 24px));
  }
  
  .score-container {
    text-align: left;
    margin-left: 10px;
  }
  
  .score-divider-wrapper {
    height: 100%;
    line-height: 100%;  
    vertical-align: middle;
  }
  
  .score-divider {
    height: 60%;
    top: 20%;
    background-color:rgba(180, 191, 219, 1);
    margin-left: 10px;
    margin-right: 30px;
  }
  
  .MuiRating-iconFilled {
    color: red;
  }
  
  .score-rating .MuiRating-root label .MuiRating-iconEmpty {
    color: rgba(180, 191, 219, 1);
  }
  
  .summary-mobile {  
    text-align: left;
    color: rgba(180, 191, 219, 1);
    margin-left: 5vw;
    margin-right: 5vw;
  }
  
  .summary-mobile .summary-title {
    font-size: 1rem;
    font-weight: 500;
    color: white;
  }
  
  .highlights-mobile  { 
    text-align: left;
    font-size: 1rem;
    line-height: 1rem;
    color: rgba(180, 191, 219, 1);  
    margin-left: 5vw;
    margin-right: 5vw;    
    margin-top: 5vh;
    margin-bottom: 10vh;
  }
  
 .highlights-mobile .highlights-title {
    color: rgba(255, 184, 79, 1);
    font-size: 16px;
  }

  .mobile-highlight-icon {
    margin-right: 10px;
  }
  
  .summary-mobile .highlight-content {
    margin-left: 5vw;
    margin-right: 5vw;    
    font-size: 1rem;
    margin-top: calc(min(3vw, 24px) / 2);
    margin-bottom: 10px;
  }
  
  .summary-mobile  .summary-content {
    line-height: 22px;
    font-size: 14px;
  }
  
  .details-mobile-container .detail-body {
    height: 100vh;
    background-color: rgba(8, 8, 17, 0.95);
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .details-mobile-container .detail-body::-webkit-scrollbar {  
    display: none;
  }
  
  .footer .disclaimer-mobile-link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    background: linear-gradient(90.16deg, rgba(255, 255, 255, 0.66) 22.68%, rgba(180, 191, 219, 0.66) 97.52%);
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  .disclaimer-mobile-title {
    text-align: left;
    margin-top: 2vh;
    font-size: 1rem;
    margin-left: 2vw;
    color: rgba(255, 255, 255, 1);
  }
  
  .disclaimer-mobile-subtitle {
    text-align: left;
    font-size: 1rem;    
    margin-top: 2vh;
    margin-left: 2vw;
    color: rgba(255, 255, 255, 1);
  }
  
  .disclaimer-mobile-content {
    text-align: left;
    font-size: 0.75rem;
    margin-left: 2vw;
    color: rgba(180, 191, 219, 1);
  }
  
  .disclaimer-mobile-list li {
    margin-top: 20px;  
  }

  .search-button-mobile {
    width: 90vw;
    height: min(60px, 5vh);
    border: 0px;
    border-radius: 0;
    background-color: rgba(222, 14, 40, 1);
    box-shadow: 0px 4px 15px 0px rgba(64, 0, 8, 0.3);
    color: white;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 20px;
  }

  .search-page {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 10;
    background-color: black;
  }

  .search-divider {
    background-color: lightblue;
  }

  .ant-input-affix-wrapper {
    padding: 0px;
    border: 0px;
    width: 90vw;
    background: black;
  }

  .ant-input-affix-wrapper .ant-input-prefix {
    margin-inline: 0px;
    background-color: rgba(8, 8, 17, 0.95);
    padding-bottom: 10px;
  }

  .search-page .ant-input-affix-wrapper input {
    background-color: black;
    color: white;
    padding-bottom: 10px;
  }

  .search-page .ant-input-affix-wrapper input::placeholder {
    background-color: black;
    color: rgba(255, 255, 255, 0.33);
  }

  .search-page .ant-input-affix-wrapper-focused {    
    border: 0px;
    box-shadow: none;
  }

  .search-page-subtitle {
    text-align: left;    
    text-transform: uppercase;
    font-size: 0.75rem;
    color: rgba(180, 191, 219, 1);
    font-weight: 400;
  }

  .search-page-options {
    text-align: left;
    margin-left: 5vw; 
    overflow-y: scroll;   
    max-height: 85vh;
  }

  .search-page-options div {    
    font-size: 1rem; 
    padding-top: 10px;
    color: rgba(180, 191, 219, 1);
  }
  
  .disclaimer-mobile-container {
    background-color: rgba(8, 8, 17, 0.95);
    position: fixed;
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    overflow: auto;
  }

  .scores {
    border-top: 1px solid rgba(0, 134, 194, 0.33);
    margin-top: 10px;
    margin-left: 5vw;
    margin-right: 5vw;
  }  

  .scores .score-border {
    border-bottom: 1px solid rgba(0, 134, 194, 0.33);
    width: 90vw;
  }

.search-bar-container-mobile {
    position: absolute;
    top: 50vh;
    padding: 10px;
}

.search-bar-container-mobile div .ant-select .ant-select-selector {
  background: black;
  height: 60px;
  width: min(60vw, 450px);
}

.search-bar-container-mobile div .ant-select .ant-select-selector {
  border: 1px;
}

.search-bar-container-mobile div .ant-select .ant-select-selector .ant-select-selection-placeholder {
  color: gray;
  font-size: 1rem;
  vertical-align: middle;
}

.search-bar-container-mobile div .ant-select .ant-select-selector .ant-select-selection-search input{
  height: 60px;
  color: white;
  font-size: 1rem;
  vertical-align: middle;
  line-height: 60px;  
}

.App-header-mobile {
  background-image: url("../public/background-mobile.png");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-size: cover;
  background-position: center;
  color: white;
}

.mobile-search-divider {
    border-bottom: 1px solid rgba(0, 134, 194, 0.33);
}

.mobile-search-page-item {
  padding: 8px 4px 8px 4px;
  font-size: 16px;
}

.details-mobile-container::-webkit-scrollbar {
  width: 5px;
}

.details-mobile-container::-webkit-scrollbar-thumb {
  background: rgba(0, 134, 194, 0.11);
}

.search-page-options::-webkit-scrollbar {
  width: 5px;
}

.search-page-options::-webkit-scrollbar-thumb {
  background: rgba(0, 134, 194, 0.11);
}